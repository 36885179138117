//FUNCTIONS
@function url-friendly-color($color) {
  @return '%23' + str-slice('#{$color}', 2, -1);
}

//COLORS
$logo-onLight: color('base-darker');
$logo-onDark: color('base-lightest');
$dark: color('ink');
$dark-accent: color('base-darker');
$dark-link: color('primary');
$dark-link-active: darken($dark-link, 15%);
$dark-link-focus: darken($dark-link, 5%);
$dark-link-hover: darken($dark-link, 10%);
$light: #ffffff;
$light-accent: color('primary-lighter');
$light-link: color('base-light');
$light-hover: darken($light, 10%);
$light-active: darken($light, 15%);
$light-link-active: darken($light-link, 15%);
$light-link-focus: darken($light-link, 5%);
$light-link-hover: darken($light-link, 10%);

$colors: (
  'accent-cool-lighter': (
    'bg': color('accent-cool-lighter'),
    'text': $dark,
    'link': $dark-link,
    'link-active': $dark-link-active,
    'link-focus': $dark-link-focus,
    'link-hover': $dark-link-hover,
    'accent': $dark-accent,
    'palette': 'Accent Cool',
  ),
  'accent-cool': (
    'bg': color('accent-cool'),
    'text': $dark,
    'link': $dark-link,
    'link-active': $dark-link-active,
    'link-focus': $dark-link-focus,
    'link-hover': $dark-link-hover,
    'accent': $dark-accent,
    'palette': 'Accent Cool',
  ),
  'accent-cool-darker': (
    'bg': color('accent-cool-darker'),
    'text': $light,
    'link': $light-link,
    'link-active': $light-link-active,
    'link-focus': $light-link-focus,
    'link-hover': $light-link-hover,
    'accent': $light-accent,
    'palette': 'Accent Cool',
  ),
  'accent-warm-lighter': (
    'bg': color('accent-warm-lighter'),
    'text': $dark,
    'link': $dark-link,
    'link-active': $dark-link-active,
    'link-focus': $dark-link-focus,
    'link-hover': $dark-link-hover,
    'accent': $dark-accent,
    'palette': 'Accent Warm',
  ),
  'accent-warm': (
    'bg': color('accent-warm'),
    'text': $dark,
    'link': $dark-link,
    'link-active': $dark-link-active,
    'link-focus': $dark-link-focus,
    'link-hover': $dark-link-hover,
    'accent': $dark-accent,
    'palette': 'Accent Warm',
  ),
  'accent-warm-dark': (
    'bg': color('accent-warm-dark'),
    'text': $light,
    'link': $light-link,
    'link-active': $light-link-active,
    'link-focus': $light-link-focus,
    'link-hover': $light-link-hover,
    'accent': $light-accent,
    'palette': 'Accent Warm',
  ),
  'base-lightest': (
    'bg': color('base-lightest'),
    'text': $dark,
    'link': $dark-link,
    'link-active': $dark-link-active,
    'link-focus': $dark-link-focus,
    'link-hover': $dark-link-hover,
    'accent': $dark-accent,
    'palette': 'Base',
  ),
  'base-lighter': (
    'bg': color('base-lighter'),
    'text': $dark,
    'link': $dark-link,
    'link-active': $dark-link-active,
    'link-focus': $dark-link-focus,
    'link-hover': $dark-link-hover,
    'accent': $dark-accent,
    'palette': 'Base',
  ),
  'base-light': (
    'bg': color('base-light'),
    'text': $dark,
    'link': $dark-link,
    'link-active': $dark-link-active,
    'link-focus': $dark-link-focus,
    'link-hover': $dark-link-hover,
    'accent': $dark-accent,
    'palette': 'Base',
  ),
  'base': (
    'bg': color('base'),
    'text': $light,
    'link': $light-link,
    'link-active': $light-link-active,
    'link-focus': $light-link-focus,
    'link-hover': $light-link-hover,
    'accent': $light-accent,
    'palette': 'Base',
  ),
  'base-dark': (
    'bg': color('base-dark'),
    'text': $light,
    'link': $light-link,
    'link-active': $light-link-active,
    'link-focus': $light-link-focus,
    'link-hover': $light-link-hover,
    'accent': $light-accent,
    'palette': 'Base',
  ),
  'base-darker': (
    'bg': color('base-darker'),
    'text': $light,
    'link': $light-link,
    'link-active': $light-link-active,
    'link-focus': $light-link-focus,
    'link-hover': $light-link-hover,
    'accent': $light-accent,
    'palette': 'Base',
  ),
  'base-darkest': (
    'bg': color('base-darkest'),
    'text': $light,
    'link': $light-link,
    'link-active': $light-link-active,
    'link-focus': $light-link-focus,
    'link-hover': $light-link-hover,
    'accent': $light-accent,
    'palette': 'Base',
  ),
  'error-dark': (
    'bg': color('secondary-dark'),
    'text': $light,
    'link': $light-link,
    'link-active': $light-link-active,
    'link-focus': $light-link-focus,
    'link-hover': $light-link-hover,
    'accent': $light-accent,
  ),
  'error': (
    'bg': color('secondary'),
    'text': $light,
    'link': $light-link,
    'link-active': $light-link-active,
    'link-focus': $light-link-focus,
    'link-hover': $light-link-hover,
    'accent': $light-accent,
  ),
  'error-light': (
    'bg': color('secondary-lighter'),
    'text': $dark,
    'link': $dark-link,
    'link-active': $dark-link-active,
    'link-focus': $dark-link-focus,
    'link-hover': $dark-link-hover,
    'accent': $dark-accent,
  ),
  'emergency': (
    'bg': color('emergency'),
    'text': $light,
    'link': $light-link,
    'link-active': $light-link-active,
    'link-focus': $light-link-focus,
    'link-hover': $light-link-hover,
    'accent': $light-accent,
  ),
  'ink': (
    'bg': color('ink'),
    'text': $light,
    'link': $light-link,
    'link-active': $light-link-active,
    'link-focus': $light-link-focus,
    'link-hover': $light-link-hover,
    'accent': $light-accent,
  ),
  'primary': (
    'bg': color('primary'),
    'text': $light,
    'link': $light,
    'link-hover': $light-hover,
    'link-active': $light-active,
    'accent': $light-accent,
  ),
  'primary-dark': (
    'bg': color('primary-dark'),
    'text': $light,
    'link': $light-link,
    'link-active': $light-link-active,
    'link-focus': $light-link-focus,
    'link-hover': $light-link-hover,
    'accent': $light-accent,
  ),
  'primary-darker': (
    'bg': color('primary-darker'),
    'text': $light,
    'link': $light-link,
    'link-active': $light-link-active,
    'link-focus': $light-link-focus,
    'link-hover': $light-link-hover,
    'accent': $light-accent,
  ),
  'primary-light': (
    'bg': color('primary-light'),
    'text': $dark,
    'link': $dark-link,
    'link-active': $dark-link-active,
    'link-focus': $dark-link-focus,
    'link-hover': $dark-link-hover,
    'accent': $dark-accent,
  ),
  'primary-lighter': (
    'bg': color('primary-lighter'),
    'text': $dark,
    'link': $dark-link,
    'link-active': $dark-link-active,
    'link-focus': $dark-link-focus,
    'link-hover': $dark-link-hover,
    'accent': $dark-accent,
  ),
  'secondary': (
    'bg': color('secondary'),
    'text': $light,
    'link': $light-link,
    'link-active': $light-link-active,
    'link-focus': $light-link-focus,
    'link-hover': $light-link-hover,
    'accent': $light-accent,
  ),
  'success-darker': (
    'bg': color('success-darker'),
    'text': $light,
    'link': $light-link,
    'link-active': $light-link-active,
    'link-focus': $light-link-focus,
    'link-hover': $light-link-hover,
    'accent': $light-accent,
  ),
  'success-dark': (
    'bg': color('success-dark'),
    'text': $light,
    'link': $light-link,
    'link-active': $light-link-active,
    'link-focus': $light-link-focus,
    'link-hover': $light-link-hover,
    'accent': $light-accent,
  ),
  'success-light': (
    'bg': color('success-light'),
    'text': $dark,
    'link': $dark-link,
    'link-active': $dark-link-active,
    'link-focus': $dark-link-focus,
    'link-hover': $dark-link-hover,
    'accent': $dark-accent,
  ),
  'success-lighter': (
    'bg': color('success-lighter'),
    'text': $dark,
    'link': $dark-link,
    'link-active': $dark-link-active,
    'link-focus': $dark-link-focus,
    'link-hover': $dark-link-hover,
    'accent': $dark-accent,
  ),
  'warning-dark': (
    'bg': color('accent-warm-darker'),
    'text': $light,
    'link': $light-link,
    'link-active': $light-link-active,
    'link-focus': $light-link-focus,
    'link-hover': $light-link-hover,
    'accent': $light-accent,
  ),
  'warning': (
    'bg': color('accent-warm-dark'),
    'text': $dark,
    'link': $dark-link,
    'link-active': $dark-link-active,
    'link-focus': $dark-link-focus,
    'link-hover': $dark-link-hover,
    'accent': $dark-accent,
  ),
  'warning-light': (
    'bg': color('accent-warm-lighter'),
    'text': $dark,
    'link': $dark-link,
    'link-active': $dark-link-active,
    'link-focus': $dark-link-focus,
    'link-hover': $dark-link-hover,
    'accent': $dark-accent,
  ),
  'white': (
    'bg': #ffffff,
    'text': $dark,
    'link': $dark-link,
    'link-active': $dark-link-active,
    'link-focus': $dark-link-focus,
    'link-hover': $dark-link-hover,
    'accent': $dark-accent,
  ),
);

$onDark: (
  'primary-light',
  'primary-lighter',
  'secondary-light',
  'secondary-lighter',
  'accent-cool-light',
  'accent-cool-lighter',
  'accent-warm-light',
  'accent-warm-lighter',
  'base-light',
  'base-lighter',
  'base-lightest',
  'white',
  'gray-5',
  'gray-10',
  'success-light',
  'error-light',
  'warning-light'
);

@each $color, $c in $colors {
  @each $colortype, $subcolor in $c {
    @if #{$colortype} == 'bg' {
      .theme-#{$color}--#{$colortype} {
        background-color: #{$subcolor};
      }
      .theme-#{$color} {
        .theme--#{$colortype},
        &.theme--#{$colortype} {
          background-color: #{$subcolor};
        }
      }
    }
    @if #{$colortype} == 'text' {
      .theme-#{$color}--#{$colortype} {
        color: #{$subcolor};
      }
      .theme-#{$color} {
        .theme--#{$colortype},
        &.theme--#{$colortype} {
          color: #{$subcolor};

          &:active,
          &.active {
            color: darken($subcolor, 15%);
          }
          &:focus,
          &.focus {
            color: darken($subcolor, 15%);
          }
          &:hover,
          &.hover {
            color: darken($subcolor, 10%);
          }
        }
      }
    }
    @if #{$colortype} == 'link' {
      .theme-#{$color}--#{$colortype},
      .theme-#{$color}--#{$colortype}:visited {
        color: #{$subcolor};
      }
      .theme-#{$color} {
        .theme--#{$colortype},
        &.theme--#{$colortype},
        &:visited {
          color: #{$subcolor};
        }
      }
    }
    @if #{$colortype} == 'link-hover' {
      .theme-#{$color}--link {
        &:hover,
        &.hover {
          color: #{$subcolor};
        }
      }
      .theme-#{$color} {
        .theme--link,
        &.theme--link {
          &:hover,
          &.hover {
            color: #{$subcolor};
          }
        }
      }
    }
    @if #{$colortype} == 'link-focus' {
      .theme-#{$color}--link {
        &:focus,
        &.focus {
          color: #{$subcolor};
        }
      }
      .theme-#{$color} {
        .theme--link,
        &.theme--link {
          &:focus,
          &.focus {
            color: #{$subcolor};
          }
        }
      }
    }
    @if #{$colortype} == 'link-active' {
      .theme-#{$color}--link {
        &:active,
        &.active {
          color: #{$subcolor};
        }
      }
      .theme-#{$color} {
        .theme--link,
        &.theme--link {
          &:active,
          &.active {
            color: #{$subcolor};
          }
        }
      }
    }
    @if #{$colortype} == 'accent' {
      .theme-#{$color}--#{$colortype} {
        color: #{$subcolor};
      }
      .theme-#{$color} {
        .theme--#{$colortype},
        &.theme--#{$colortype} {
          color: #{$subcolor};
        }
      }
    }
  }
}
