.markdown {
  background-color: white;
  padding: 0.25rem;
  margin: 0 0 1rem 0;

  & .header {
    line-height: 2;
    font-weight: 500;
    text-align: center;
    margin: 0 0 1rem 0;
    border-bottom: solid 1px black;
    border-top: solid 1px black;
    display: grid;

    &:hover {
      cursor: pointer;
    }
  }

  & > .markdown-list {
    display: grid;
    grid-template-rows: repeat(4, 1fr);
    gap: 0.5rem;

    &.hidden {
      display: none;
    }

    & > .mark-down-ref {
      line-height: 2;
    }

    & > .markdown-items {
      gap: 0.5rem;
      display: grid;
      grid-template-columns: 150px 1fr 1fr;
      align-self: baseline;
      border-bottom: 1px solid black;

      > .markdown-format-result {
        & a::after {
          content: ' ';
          background-repeat: no-repeat;
          padding: 0rem 0.75rem;
          margin: 0 0 0 0.25rem;
          background-image: url("data:image/svg+xml;charset=UTF-8, %3Csvg version='1.1' width='100%'  height='100%' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' stroke='%23005ea2' fill='none' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M8.5 4H6C4.89543 4 4 4.89543 4 6V17C4 18.1046 4.89543 19 6 19H17C18.1046 19 19 18.1046 19 17V14.5'/%3E%3Cpath d='M13 4H19V10'/%3E%3Cpath d='M19 4L12 11'/%3E%3C/svg%3E");
        }
      }

      &:last-child {
        margin: 0 0 1rem 0;
      }
    }
  }
}
