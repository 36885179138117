$sizes: 0, '1px', '2px', '05', 1, '105', 2, '205', 3;

iframe:not(.outline-none):focus,
[href]:not(.outline-none):focus,
[tabindex]:not(.outline-none):focus,
[contentEditable='true']:not(.outline-none):focus,
input:not([disabled]):not(.outline-none):focus,
select:not([disabled]):not(.outline-none):focus,
textarea:not([disabled]):not(.outline-none):focus,
button:not([disabled]):not(.outline-none):focus {
  outline: units('2px') solid #2491ff;
  // outline-offset: units('05');
}

*:focus {
  outline-offset: units('05');
}

@each $s in $sizes {
  *:focus > .outline-offset-neg-#{$s},
  .outline-offset-neg-#{$s}:focus {
    outline: units('2px') solid #2491ff;
    outline-offset: calc(units($s) * -1);
  }
  *:focus > .outline-offset-#{$s},
  .outline-offset-#{$s}:focus {
    outline: units('2px') solid #2491ff;
    outline-offset: units($s);
  }

  .outline-#{$s} {
    outline: units($s) solid #2491ff;
  }
}

.outline-none,
.outline-none:focus {
  outline: none !important;
}
