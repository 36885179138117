.announcement-modal {
  position: fixed;
  z-index: 1000;
  background: white;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  width: 620px;
  border-radius: 5px;
  border: 1px solid #dedede;
  padding: 10px;
  .nac-icon--xs {
    display: none;
  }
  .announcement-title {
    width: 100%;
    text-align: center;
  }
  .announcement-text {
    padding-top: 5px;
    margin-bottom: 10px;
    width: 100%;
  }
  .announcement-textarea {
    border: 1px solid gray;
    width: 100%;
    height: 100px;
  }
}
