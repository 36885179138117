h1 {
  @include u-font('serif', 'xl');
  font-weight: normal;
  margin: 0;
}

h2 {
  @include u-font('sans', 'md');
  color: color('base-darker');
  margin-bottom: 1rem;
}

h3 {
  @include u-font('sans', 'xs');
  color: color('base-dark');
  font-weight: normal;
  margin-bottom: 0.5rem;
}

@media (max-width: units(tablet)) {
  h1 {
    @include u-font('serif', 'lg');
    font-weight: normal;
    margin: 0;
  }
}
