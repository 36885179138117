//CUSTOMTHEME
@import './headings.scss';
@import './flex.scss';
@import './position.scss';
@import './components/header';
@import './outline.scss';
@import './components/nav';
@import './components/search';
@import './components/footer.css';
@import '../components/Tooltip/tooltip.scss';
@import './components/loader';
@import '../components/Icon/icon.scss';
@import '../components/Button/button.scss';
@import '../components/Pages/pages.scss';
@import '../components/ListSeparator/separator.scss';
@import '../components/Pill/pill.scss';
@import '../components/Logo/logo.scss';
@import '../components/Table/table.scss';
@import '../components/ContentLoaders/contentloader.scss';
@import '../components/Templates/Results/results.scss';
@import './shadows.scss';
@import '../components/DigitalObjects/digitalobjects.scss';
@import '../components/utilities/CopyUrl/copyurl.scss';
@import '../components/form/radio-buttons.scss';
@import '../components/form/Checkbox/checkbox.scss';
@import '../components/Modal/modal.scss';
@import '../components/Avatar/avatar.scss';
@import '../components/Alert/alert.scss';
@import '../components/Pages/HelpCenter/help-center.scss';
@import '../components/Toast/toast.scss';
@import './patterns.scss';
@import '../components/Highlighter/highlighter.scss';
@import '../components/FlyOutMenu/fly-out-menu.scss';
@import '../components/PDFViewer/pdf-viewer.scss';
@import '../components/basiclink.scss';
@import './components/listpanel.css';

html {
  height: 100%;
  overflow: auto;
}

body {
  color: color('base-darkest');
  height: 100%;
}

hr {
  border: none;
  border-top: 1px solid color('base-lighter');
}

p {
  margin-bottom: units(1);
}
.nac-button,
.nac-button *,
.no-underline,
.no-underline:hover *,
.no-underline:active *,
.no-underline:focus *,
.no-underline *,
.no-underline *:hover,
.no-underline *:active,
.no-underline *:focus {
  text-decoration: none !important;
}

.border-warning-dark {
  border-color: color('accent-warm-darker');
}
.text-warning-dark {
  color: color('accent-warm-darker');
}
.text-warning {
  color: color('accent-warm-dark');
}
.text-warning-light {
  color: color('accent-warm-lighter');
}

.text-success-light {
  color: color('green-cool-20v');
}
.text-success-lighter {
  color: color('success-lighter');
}

.right-neg-4 {
  right: -2.5rem;
}

*,
::after,
::before {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

html {
  background: color(primary-darker);
  // overflow-x: clip;
  width: 100vw;
}

body {
  &.ReactModal__Body--open {
    overflow: hidden;
  }
  // overflow-x: clip;
}

a {
  color: color('primary');
  text-decoration: none;

  &:hover,
  &:focus {
    color: color('primary-dark');
    text-decoration: underline;
  }
}

img,
embed,
object,
video {
  max-width: 100%;
}

.nac-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-position: center;
  background-size: auto 400%;
  display: flex;
  justify-content: center;
  // height: calc(60vh);
  min-height: 20rem;
  width: 100%;
}

.nac-home-bg {
  height: calc(60vh);
}

.word-break-all {
  word-break: break-all;
}
.word-break-word {
  word-break: break-word;
}

.clickable {
  cursor: pointer;
  &:hover {
    a {
      color: inherit;
      text-decoration: underline;
    }
  }
}

.linkable {
  &:hover {
    text-decoration: underline;
  }
}

.to-front {
  z-index: 9999;
}

.bg-transparent {
  background-color: transparent;
}
.border-transparent {
  border: 1px solid rgba(0, 0, 0, 0);
}
/*.nac-pill:last-child {
  margin-right: 0;
}*/

.margin-btn {
  right: -45px;
}

/*.nac-results-page-area {
  display: flex;
  padding-right: 3em;
}*/

.nac-search-results__filter {
  flex: 0 0 15em;
  /*border: 1px solid color(primary-dark);
  background-color: #d9e8f6;
  display: inline-block;
  align-items: center;
  width: 20%;
  height: auto;
  margin-left: 2em;
  margin-right: 2em;*/
}
.nac-search-results__container {
  align-items: center;
  background-color: #d9e8f6;
  background-position: center;
  background-size: auto 400%;
  display: flex;
  justify-content: center;
  padding-bottom: 10rem;
}

.nac-pill-search {
  background: transparent;
  border: none;

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: color('primary');
    opacity: 0.5;
  }
}

ol.list-with-margin,
ul.list-with-margin {
  li {
    margin-bottom: units(1);
  }
  li:last-child {
    margin-bottom: 0;
  }
}

.usa-prose > table thead th,
.usa-table thead th {
  @include u-font('sans', 'sm');
  color: color('base-darkest');
  font-weight: bold;
}

$breakpoint-alpha: 600px; // adjust to your needs

.usa-table {
  th {
    border: none;
    border-bottom: 1px solid color(base-lighter);
    display: none;
  }

  td {
    display: flex;

    &:first-child {
      padding-top: 0.5em;
    }
    &:last-child {
      border-bottom: 1px solid color(base-lighter);
      padding-bottom: 0.5em;
    }

    &:before {
      color: color(base);
      content: attr(data-th) ' ';
      font-size: size('sans', '2xs');
      font-weight: normal;
      width: 6.5em; // magic number :( adjust according to your own content
      display: inline-block;
      text-transform: uppercase;

      @media (min-width: $breakpoint-alpha) {
        display: none;
      }
    }
  }

  th,
  td {
    //text-align: left;
    border: none;

    @media (min-width: $breakpoint-alpha) {
      border-bottom: 1px solid color(base-lighter);
      display: table-cell;
      padding: 0.25em 0.5em;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }
  tr:last-child td {
    border-bottom: none;
  }
}

// .radius-pill {
//   border-radius: 1.5rem;
// }

.bg-primary-lightest {
  background-color: #eef5fb;
}
.usa-nav__primary > .usa-nav__primary-item > a,
.usa-nav__primary > .usa-nav__primary-item > a:focus,
.usa-nav__primary > .usa-nav__primary-item > a:visited {
  color: color(base-lighter);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@keyframes autofill {
  to {
    background-image: url("data:image/svg+xml;charset=UTF-8, %3Csvg version='1.1' width='100%'  height='100%' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke-linecap='round' stroke-linejoin='round' stroke='%2371767a' stroke-width='1' %3E%3Cpath vector-effect='non-scaling-stroke' d='M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z'/%3E%3Cpath vector-effect='non-scaling-stroke' d='M20.9999 20.9999L16.6499 16.6499'/%3E%3C/svg%3E") !important;
  }
}

@-webkit-keyframes autofill {
  to {
    background-image: url("data:image/svg+xml;charset=UTF-8, %3Csvg version='1.1' width='100%'  height='100%' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke-linecap='round' stroke-linejoin='round' stroke='%2371767a' stroke-width='1' %3E%3Cpath vector-effect='non-scaling-stroke' d='M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z'/%3E%3Cpath vector-effect='non-scaling-stroke' d='M20.9999 20.9999L16.6499 16.6499'/%3E%3C/svg%3E") !important;
  }
}

input:-webkit-autofill {
  animation-name: autofill !important;
  animation-fill-mode: both;
  -webkit-animation-name: autofill !important;
  -webkit-animation-fill-mode: both;
}

// .form-control:-webkit-autofill,
// .form-control:-webkit-autofill:hover,
// .form-control:-webkit-autofill:focus,
// .form-control:-webkit-autofill:active {
//   -webkit-box-shadow: 0 0 0 30px white inset !important;
//   box-shadow: 0 0 0 30px white inset !important;
// }

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.visibility-hidden {
  visibility: hidden;
}

.visibility-visible {
  visibility: visible;
}

.usa-accordion__heading {
  border-top: 1px solid color(base-lighter);
}
.usa-accordion__heading:not(:first-child),
.usa-prose .usa-accordion__heading:not(:first-child) {
  margin-top: 0;
}

.usa-nav .usa-accordion {
  align-items: center;
}

.nac-filter-item {
  &:hover,
  &:active {
    background-color: color(base-lighter);
    .filter-link {
      color: color(primary-dark);
      text-decoration: underline;
    }
  }
  .filter-link {
    color: color(primary);
  }
}

.nac-link {
  color: color(primary);
  cursor: pointer;
  &:hover,
  &:active {
    color: color(primary-dark);
    text-decoration: underline;
  }
}

/*
.usa-card {
  .usa-card__img {
    align-items: center;
    justify-content: center;
    background-color: transparent;
    display: flex;
    height: 100%;
    object-fit: contain;
    overflow: visible;
    width: 100%;
    .icon {
      object-fit: contain;
      max-height: 8rem;
    }
  }
}

@media (min-width: 40em) {
  .usa-card--flag .usa-card__media {
    display: block;
  }
}

.usa-card__container {
  border-width: 1px;
  border-color: color(base-light);
  border-radius: 0;
}
.usa-card__body:last-child {
  padding-bottom: 0;
}

.list-view {
  &.usa-card {
    margin-bottom: 0;
  }
  .usa-card__container {
    border: none;
    @include u-minh('card');
  }
}
@media (min-width: 40em) {
  .usa-card {
    margin-bottom: 1rem;
  }
  .usa-card--flag .usa-card__media {
    width: 10rem;
  }
  .usa-card--flag .usa-card__header,
  .usa-card--flag .usa-card__body,
  .usa-card--flag .usa-card__footer {
    margin-left: 11rem;
  }
}*/

.nowrap {
  flex: 1 1 auto;
  white-space: nowrap;
}

.ellipsed {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.ellipsed-line-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.ellipsed-line-4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

.ellipsed-line-6 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6; /* number of lines to show */
  line-clamp: 6;
  -webkit-box-orient: vertical;
}

.ellipsed-line-8 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 8; /* number of lines to show */
  line-clamp: 8;
  -webkit-box-orient: vertical;
}

.ellipsed-line-10 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 10; /* number of lines to show */
  line-clamp: 10;
  -webkit-box-orient: vertical;
}

.not-ellipsed {
  text-overflow: unset;
  overflow: visible;
  white-space: pre-wrap;
}

.white-space-pre-wrap {
  white-space: pre-wrap;
}
.white-space-pre-line {
  white-space: pre-line;
}

$opacities: (
  '100': 1,
  '90': 0.9,
  '80': 0.8,
  '70': 0.7,
  '60': 0.6,
  '50': 0.5,
  '40': 0.4,
  '30': 0.3,
  '20': 0.2,
  '10': 0.1,
  '0': 0,
);

$colorNames: (
  // 'gray-10',
  // 'gray-20',
  // 'gray-30',
  // 'gray-40',
  // 'gray-50',
  // 'gray-60',
  // 'gray-70',
  // 'gray-80',
  // 'gray-90',
  'accent-cool',
  'accent-cool-light',
  'base-lightest',
  'base-lighter',
  'base-light',
  'base',
  'base-darker',
  'base-darkest',
  'error',
  'error-light',
  'primary-dark',
  'primary-lighter',
  'primary-light',
  'primary',
  'success',
  'success-light',
  'warning-light'
);

@each $opacity, $i in $opacities {
  @each $color in $colorNames {
    .bg-#{$color}-opacity-#{$opacity} {
      background-color: rgba(color(#{$color}), $i);
    }

    .hover\:bg-#{$color}-opacity-#{$opacity}:hover {
      background-color: rgba(color(#{$color}), $i);
    }

    .text-#{$color}-opacity-#{$opacity} {
      color: rgba(color(#{$color}), $i);
    }
  }
}

.bg-gradient-white-opacity-0--white-opacity-100 {
  background: transparent;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}

.bg-gradient-base-lightest-opacity-0--base-lightest-opacity-100 {
  background: transparent;
  background: linear-gradient(
    180deg,
    rgba(240, 240, 240, 0) 0%,
    rgba(240, 240, 240, 1) 100%
  );
}

@media screen and (min-width: units(tablet-lg)) {
  .tablet-lg\:visibility-visible {
    visibility: visible;
  }

  .tablet-lg\:flex-row {
    flex-direction: row;
    &.tablet-lg\:grid-gap-0,
    &.tablet-lg\:grid-gap-none {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
    &.tablet-lg\:flex-gap {
      gap: units(2);
      &-0 {
        gap: 0px;
      }
      &-xs {
        gap: units(05);
      }
      &-sm {
        gap: units(1);
      }
      &-md {
        gap: units(2);
      }
      &-lg {
        gap: units(4);
      }
      & > section {
        margin-right: units(8);
      }
    }
  }
}

.bg-position-right-center {
  background-position: right center;
}
.bg-position-left-center {
  background-position: left center;
}
.bg-position-2-center {
  background-position: units(2) center;
}

.object-fit-cover {
  object-fit: cover;
}

.export-message {
  font-weight: 600;
  line-height: 1;
  font-style: italic;
  font-size: 1rem;
  margin: 0.5rem 0;
}

.text-emergency {
  color: color(emergency);
}
.border-emergency {
  border-color: color(emergency);
}

.transform-none {
  transform: none;
}

.usa-accordion__button {
  background-position: left 0.5rem center;
  background-size: 1.25rem;
  padding: 1rem 1.25rem 1rem 2rem;
}

.border-box {
  box-sizing: border-box;
}

//Expand Collapse String
.height-min-string {
  height: 150px;
}

.usa-sr-only,
.disable-copy-paste {
  -webkit-user-select: none; // Chrome/Safari/Opera
  -moz-user-select: none; // Firefox
  -ms-user-select: none; // Internet Explorer/Edge
  -webkit-touch-callout: none; // iOS Safari}
  user-select: none;
}

@media screen {
  .print-footer {
    display: none;
  }
}

@page {
  size: auto; /* auto is the initial value */
  /* this affects the margin in the printer settings */
  margin: 15mm 10mm 15mm 15mm;
}

.print-table > thead,
.print-header,
.header-space,
.print-footer,
.footer-space {
  height: 0px;
  display: none;
}
table.print-table {
  border-spacing: 0;
  width: 100%;
}

@media print {
  // thead {
  //   display: table-header-group;
  // }
  // tfoot {
  //   display: table-footer-group;
  // }

  .no-print {
    display: none;
  }

  button {
    display: none;
  }
  body {
    margin: 0;
  }
  .print-table > thead,
  .print-header,
  .header-space,
  .print-footer,
  .footer-space {
    height: auto;
    display: block;
  }

  #nac-header,
  #nac-footer,
  .nac-icon {
    display: none !important;
  }

  a:after {
    content: ' [link: catalog.archives.gov' attr(href) '] ';
    font-size: 0.8em;
    font-weight: normal;
  }
  // li a:after {
  //   display: block;
  //   content: ' [link: catalog.archives.gov' attr(href) '] ';
  //   font-size: 0.8em;
  //   font-weight: normal;
  //   margin-top: units(1);
  //   width: 100%;
  // }
  .section-count {
    &:before {
      content: '(';
    }
    &:after {
      content: ')';
    }
  }

  .print-header,
  .header-space,
  .print-footer,
  .footer-space {
    display: block;
  }

  .print-header,
  .header-space {
    height: 250px;
  }

  .print-footer,
  .footer-space {
    height: 100px;
  }
  .print-header {
    position: fixed;
    top: 0;
  }
  .print-footer {
    position: fixed;
    bottom: 0;
  }
}
.leaflet-container {
  background: transparent !important;
}

.width-viewport {
  width: 100vw;
}
.maxw-viewport {
  max-width: 100vw;
}

.break-content {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.max-width-full {
  max-width: 100%;
}

.text-overflow-ellipses {
  text-overflow: ellipsis;
}

.width-card-lg-16 {
  width: 16rem;
}

@import '../components/Pages/ErrorPage/errorpage.scss';
