.wrap {
  width: 300px;
  position: absolute;
  z-index: 100000;
  top: 0px;
  right: 0px;
}

.handle {
  z-index: 1000;
  height: 400px;
}

.resize {
  border: 1px solid black;
  overflow: auto;
  width: 100%;
  height: 100%;
}

.wrapper {
  width: 100%;
  height: 100%;
}

.extracted-text-sub-title {
  font-style: italic;
  font-size: 12px;
  font-weight: 600;
}
