.loading__wrapper {
  justify-content: center;
  display: flex;
  z-index: 99999;
}

.loading__wrapper:after {
  content: '';
  border: 10px solid lightgray;
  border-top: 10px solid #007bff;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
