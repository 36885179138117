body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.icon-popout-window:first-child::after {
  content: ' ';
  background-repeat: no-repeat;
  padding: 0rem 0.75rem;
  margin: 0 0 0 0.25rem;
  background-image: url("data:image/svg+xml;charset=UTF-8, %3Csvg version='1.1' width='100%'  height='100%' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' stroke='%23005ea2' fill='none' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M8.5 4H6C4.89543 4 4 4.89543 4 6V17C4 18.1046 4.89543 19 6 19H17C18.1046 19 19 18.1046 19 17V14.5'/%3E%3Cpath d='M13 4H19V10'/%3E%3Cpath d='M19 4L12 11'/%3E%3C/svg%3E");
}

.icon-popout-window-flex:first-child::after {
  content: ' ';
  display: flex;
  background-repeat: no-repeat;
  padding: 0rem 0.75rem;
  margin: 0 0 0 0.25rem;
  background-image: url("data:image/svg+xml;charset=UTF-8, %3Csvg version='1.1' width='100%'  height='100%' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' stroke='%23005ea2' fill='none' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M8.5 4H6C4.89543 4 4 4.89543 4 6V17C4 18.1046 4.89543 19 6 19H17C18.1046 19 19 18.1046 19 17V14.5'/%3E%3Cpath d='M13 4H19V10'/%3E%3Cpath d='M19 4L12 11'/%3E%3C/svg%3E");
}

.icon-popout-window-white:first-child::after {
  content: ' ';
  background-repeat: no-repeat;
  padding: 0rem 0.75rem;
  margin: 0 0 0 0.25rem;
  background-image: url("data:image/svg+xml;charset=UTF-8, %3Csvg version='1.1' width='100%'  height='100%' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' stroke='white' fill='none' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M8.5 4H6C4.89543 4 4 4.89543 4 6V17C4 18.1046 4.89543 19 6 19H17C18.1046 19 19 18.1046 19 17V14.5'/%3E%3Cpath d='M13 4H19V10'/%3E%3Cpath d='M19 4L12 11'/%3E%3C/svg%3E");
}

/* list */
