@function url-friendly-color($color) {
  @return '%23' + str-slice('#{$color}', 2, -1);
}

$icons: (
  'newtab':
    "%3Cpath d='M8.5 4H6C4.89543 4 4 4.89543 4 6V17C4 18.1046 4.89543 19 6 19H17C18.1046 19 19 18.1046 19 17V14.5'/%3E%3Cpath d='M13 4H19V10'/%3E%3Cpath d='M19 4L12 11'/%3E",
);

$uri-svg-header-icons: "data:image/svg+xml;charset=UTF-8, %3Csvg version='1.1' width='100%'  height='100%' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'";
$uri-svg-icons-style-outline: "fill='none' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'";

.preview-button {
  display: inline-block;
  min-width: 40px;
  height: 26px;
  cursor: pointer;
}

$newtab: "%3Cpath d='M8.5 4H6C4.89543 4 4 4.89543 4 6V17C4 18.1046 4.89543 19 6 19H17C18.1046 19 19 18.1046 19 17V14.5'/%3E%3Cpath d='M13 4H19V10'/%3E%3Cpath d='M19 4L12 11'/%3E";

.preview-modal-title {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: center;
  font-size: 1.06rem;
  line-height: 1.6;
}

.link-parser {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
  text-align: center;
  font-size: 1.06rem;
  line-height: 1.6;
}

.link-parser a {
  &::after {
    content: '';
    background-image: url("#{$uri-svg-header-icons} stroke='#{url-friendly-color(#005ea2)}' #{$uri-svg-icons-style-outline}%3E#{$newtab}%3C/svg%3E");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: inline-block;
    flex: none;
    height: 1rem;
    margin-right: 0.5rem;
    margin-top: 0;
    vertical-align: middle;
    width: 1rem;
  }
}
