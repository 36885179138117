.usa-footer {
  clear: both;
  display: flex;
  padding: 1rem 2rem;
  padding-bottom: 0;
}

.usa-footer .usa-footer__primary-section {
  background-color: transparent;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

.usa-footer .usa-footer__primary-section .usa-nav__secondary-links {
  display: flex;
  flex-direction: column;
}

.usa-footer
  .usa-footer__primary-section
  .usa-nav__secondary-links
  .usa-nav__secondary-item {
  display: flex;
  padding-left: 0;
}

.usa-footer
  .usa-footer__primary-section
  .usa-nav__secondary-links
  .usa-nav__secondary-item::before {
  content: none;
  padding-right: 0;
}

.usa-footer .usa-footer__secondary-section {
  background-color: black;
  margin: 0 auto;
  max-width: 1200px;
  margin-top: 4rem;
  margin-bottom: 4rem;
  padding-top: 4rem;
  border-top: 1px solid #333333;
  display: flex;
  width: 100%;
}

.usa-footer .usa-footer__secondary-section > div {
  max-width: unset;
  padding: 0;
  width: 100%;
}

.usa-footer .usa-footer__secondary-section > div > div {
  margin: 0 auto;
}

.usa-footer .usa-footer__secondary-section ul.usa-list {
  display: flex;
  flex: 1 2 auto;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.usa-footer
  .usa-footer__secondary-section
  ul.usa-list
  .usa-footer__secondary-link {
  padding: 0 0 0 1rem;
  flex-shrink: 0;
}

.usa-footer .usa-footer__primary-link {
  color: #f0f0f0;
  font-size: unset;
  font-weight: normal;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  text-align: left;
}
