section {
  max-width: 100%;
  padding-bottom: units(3);
  padding-left: units(3);
  padding-right: units(3);
  padding-top: units(3);
}

@media (max-width: units(tablet)) {
  section {
    padding-bottom: units(2);
    padding-left: units('105');
    padding-right: units('105');
    padding-top: units(2);
  }
}

@media (min-width: 40em) {
  .usa-header + .usa-section,
  .usa-header + main {
    border-top: none;
  }
}

ul.new-lines {
  /* Set "new-line-counter" to 0 */
  counter-reset: new-line-counter;
  display: flex;
  flex-direction: column;
  gap: units(1);
  line-height: 1.2;
  list-style: none;
  // margin-bottom: units(1);
}
li.new-line {
  display: flex;
  flex-direction: row;
  gap: units('05');
  line-height: 1.2;
  list-style: none;
  word-break: break-all;
}
.new-line::before {
  @include u-font('sans', '3xs');
  align-items: flex-start;
  justify-content: center;
  content: '';
  color: color('base');
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  flex-wrap: nowrap;
  // margin-top: units('2px');
  max-width: units(4);
  min-width: units('105');
  width: 100%;
}

.new-line.numbered::before {
  /* Increment "new-line-counter" by 1 */
  counter-increment: new-line-counter;
  content: counter(new-line-counter);
}

// .new-line.marginalia::before {
//   content: "+";
// }
