.anim-opacity {
  opacity: 0;
  transition-property: opacity;
}

.active {
  & > .anim-opacity,
  &.anim-opacity {
    opacity: 1;
  }
}
.anim-duration-250 {
  transition-duration: 250ms;
}

.anim-duration-500 {
  transition-duration: 500ms;
}

.anim-duration-750 {
  transition-duration: 750ms;
}

.anim-duration-1000 {
  transition-duration: 1000ms;
}
