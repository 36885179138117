.close {
  color: #23c641;
  background-color: rgba(255, 255, 255, 0.5);
  height: fit-content;
  position: absolute;
  transform: rotate(-90deg);
  top: 56px;
  right: 165px;
  padding: 4px;
  transition: right 0.1s ease-in-out 0.1s;
}
.close:hover {
  cursor: pointer;
}
.close.open {
  position: absolute;
  right: 405px;
  top: 56px;
  padding: 4px;
}

.list-panel {
  position: fixed;
  right: -9999px;
  display: block;
  width: clamp(10rem, 15rem, 15.5rem);
  transition: right 0.1s ease-in-out 0.1s;
}
.list-panel.open {
  position: absolute;
  z-index: 500;
  top: 46px;
  right: 175px;
  opacity: 0.55;
  display: block;
  background-color: rgba(255, 255, 255, 0.5);
  height: -webkit-fill-available;
}

.list-panel .collapse-panel {
  grid-row: 1;
  display: flex;
  justify-content: flex-start;
  background-color: white;
  font-weight: 500;
  height: 100%;
  overflow-x: auto;
  flex-direction: column;
}
.list-panel .collapse-panel > .search-term-count {
  color: #23c641;
  background-color: white;
  padding: 0.5rem 0 0 0.5rem;
}
.list-panel .collapse-panel > .close {
  display: flex;
  justify-content: center;
  flex-flow: row;
  padding: 0.25rem 0.1rem;
  background-color: #ccc;
}
.list-panel .collapse-panel > .close:hover {
  cursor: pointer;
}
.list-panel .collapse-panel > .list {
  grid-auto-rows: auto;
  background-color: white;
  border-bottom: 1px;
  align-items: center;
  padding: 0.5rem 0 0 0.5rem;
  align-content: start;
}
.list-panel .collapse-panel > .list .item-url {
  grid-column: 1;
  padding: 0 0 0.5rem 0.5rem;
}
.list-panel .collapse-panel > .list .item-url > a {
  text-decoration: none;
  font-weight: 700;
}
.list-panel .collapse-panel > .list .item-url > a span.donum {
  color: #003399;
}
.list-panel .collapse-panel > .list .item-url > a span.text {
  color: #2d3133;
}

.hidden {
  position: absolute;
  top: -9999px;
  height: fit-content;
}

.show {
  display: grid;
  justify-content: flex-start;
  position: relative;
  height: 100%;
}
