.usa-search[role='search'],
.usa-search[role='search'] > div,
.usa-search [role='search'] {
  width: 80%;
  justify-content: center;
}
.usa-header {
  .usa-search[role='search'],
  .usa-search[role='search'] > div,
  .usa-search [role='search'] {
    width: 100%;
  }
  .usa-button {
    color: color('base-lightest');
    background-color: color('primary');
    &:hover {
      color: color('base-lightest');
      background-color: color('primary-dark');
      border-bottom: 0;
      text-decoration: none;
    }
  }
}
