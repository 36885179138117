@import '../settings/icons.scss';
@import '../settings/colors.scss';
@import '../settings/sizes.scss';

.nac-icon,
.nac-list-icon,
.nac-input-icon {
  background-position: center center;
  background-repeat: no-repeat;
  flex-shrink: 0;

  @each $percent, $p in $percents {
    &.nac-icon--bg-#{$percent} {
      background-size: auto #{$p};
    }
  }

  &.nac-icon--left {
    background-position: 0.5rem center;
  }

  @each $size, $s in $sizes {
    &.nac-icon--#{$size} {
      @include u-height(#{$s});
      @include u-width(#{$s});
    }
  }
}

@each $color, $c in $colors {
  @each $icon, $i in $icons {
    @each $thickness, $t in $thicknesses {
      @each $subcolor, $sub in $c {
        $style: if(
          $icon == 'bullet',
          "#{$uri-svg-icons-style-fill} fill='#{url-friendly-color($sub)}'",
          "#{$uri-svg-icons-style-outline} stroke='#{url-friendly-color($sub)}' stroke-width='#{$t}'"
        );
        @if $subcolor == 'bg' {
          .nac-icon--#{$color}.nac-icon--#{$icon}.nac-icon--#{$thickness} {
            background-image: url('#{$uri-svg-header-icons} #{$style} %3E#{$i}%3C/svg%3E');
          }
        }
        @if $subcolor == 'text' {
          .nac-icon-theme--#{$color}.nac-icon--#{$icon}.nac-icon--#{$thickness} {
            background-image: url('#{$uri-svg-header-icons} #{$style} %3E#{$i}%3C/svg%3E');
          }
        }
      }
    }
    @if $icon == 'asterisk' {
      @each $subcolor, $sub in $c {
        @if $subcolor == 'bg' {
          li.nac-list-icon--#{$color}.nac-list-icon--asterisk {
            list-style-image: url("#{$uri-svg-header-icons} stroke='#{url-friendly-color($sub)}' #{$uri-svg-icons-style-outline} stroke-width='1'%3E#{$i}%3C/svg%3E");
            // &:before {
            //   content: '';
            //   background-image: url("#{$uri-svg-header-icons} stroke='#{url-friendly-color($sub)}' #{$uri-svg-icons-style-outline} stroke-width='1'%3E#{$i}%3C/svg%3E");
            //   height: units(1);
            //   left: 0;
            //   position: absolute;
            //   width: units(1);
            // }
          }
        }
      }
    }
  }
}

//Icon Animations
.icon-rotate-cw {
  animation-name: rotate-cw;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-name: rotate-cw;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: rotate-cw;
  -moz-animation-duration: 2s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
}

@keyframes rotate-cw {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate-cw {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes rotate-cw {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
