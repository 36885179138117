.fly-out-menu {
  bottom: 0;
  left: 0;
  max-width: units('mobile');
  top: 0;
  width: 90%;

  &.right {
    left: unset;
    right: 0;
  }
}
