@import '../../settings/icons.scss';

.usa-checkbox,
.usa-radio {
  background: transparent;
}
.nac-table {
  .usa-checkbox {
    align-items: center;
    display: flex;
    height: units(3);
    width: units(3);
  }
  .usa-checkbox__label {
    height: units(3);
    padding-left: 0;
    width: units(3);
  }
}

.usa-checkbox__label {
  margin: 0;

  &.details {
    display: flex;
    flex-direction: column;
  }

  &.result_checkbox {
    &::before {
      box-shadow: 0 0 0 1px color('base-dark'), 0 0 0 6px white;
    }
  }

  &::before {
    background: white;
    border-radius: 1px;
    box-shadow: 0 0 0 1px color('base-dark');
    height: units(2);
    margin: 0;
    top: units('05');
    width: units(2);
  }
}
.usa-checkbox__input:checked + [class*='__label'],
.usa-radio__input:checked + [class*='__label'] {
  &.result_checkbox {
    &::before {
      box-shadow: 0 0 0 2px color('primary'), 0 0 0 6px #eef5fb;
    }
  }
}

.usa-checkbox__input.indeterminate + [class*='__label'] {
  &::before {
    background-color: #005ea2;
    box-shadow: 0 0 0 2px #005ea2;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 0.75rem auto;
    @each $icon, $i in $icons {
      @if $icon == 'bullet-hyphen' {
        background-image: url("#{$uri-svg-header-icons} stroke='%23FFF' #{$uri-svg-icons-style-outline} stroke-width='3'%3E#{$i}%3C/svg%3E"),
          linear-gradient(transparent, transparent);
      }
    }
  }
}
