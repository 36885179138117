.flex-basis-0 {
  flex-basis: 0;
}
.flex-basis-auto {
  flex-basis: auto;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink-1 {
  flex-shrink: 0;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-none {
  flex: none;
}

.flex-1 {
  flex: 1;
}

.flex-no-wrap {
  // This was removed because it broke the ellipsis functionality for breadcrumbs.
  // if this display is desired, separately add
  // .flex-grow-0, .flex-shrink-0, & .flex-basis-auto
  // flex: 0 0 auto;
  flex-wrap: nowrap;
}

.flex-column,
.flex-row {
  &.flex-gap {
    gap: units(2);
    &-0 {
      gap: 0px;
    }
    &-2xs {
      gap: units('2px');
    }
    &-xs {
      gap: units('05');
    }
    &-sm {
      gap: units(1);
    }
    &-md {
      gap: units(4);
    }
    &-lg {
      gap: units(8);
    }
    & > section {
      margin-right: units(8);
    }
  }
}

.flex-row.grid-gap-0,
.flex-row.grid-gap-none {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.flex-column.grid-gap-0,
.flex-column.grid-gap-none {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

@media screen and (min-width: units(tablet-lg)) {
  .flex-gap,
  .flex-row,
  .flex-column {
    &.tablet-lg\:flex-gap {
      gap: units(2);
      &-0 {
        gap: 0px;
      }
      &-2xs {
        gap: units('2px');
      }
      &-xs {
        gap: units('05');
      }
      &-sm {
        gap: units(1);
      }
      &-md {
        gap: units(4);
      }
      &-lg {
        gap: units(8);
      }
      & > section {
        margin-right: units(8);
      }
    }
    &.tablet-lg\:grid-gap-0,
    &.tablet-lg\:grid-gap-none {
      margin: 0;
    }

    &.tablet-lg\:flex-column {
      flex-direction: column;
      &.tablet-lg\:grid-gap-0,
      &.tablet-lg\:grid-gap-none {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
      }
    }
  }
}
