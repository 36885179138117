.left-half {
  left: 50%;
  transform: translateX(-50%);
}

.top-half {
  top: 50%;
  transform: translateY(-50%);
}

.margin-top-neg-card {
  margin-top: calc(units('card') * -1);
}
