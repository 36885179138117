.pagination {
  list-style: none;
  display: flex;
  margin: 0.5rem 0;

  & > li {
    margin: 0 0.75rem 0 0;
    &:last-child {
      margin-left: auto;
    }
  }
}
