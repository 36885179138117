.transform-scale-1 {
  transform: scale(1);
}
.transform-scale-2 {
  transform: scale(2);
}
.transform-scale-3 {
  transform: scale(3);
}
.transform-scale-4 {
  transform: scale(4);
}
