@import '../settings/icons.scss';
@import '../settings/colors.scss';
@import '../settings/sizes.scss';

/**
 * This preferred style deviates from the USWDS alert styling in order to leverage the custom NextGen NAC Icons
 */
.usa-alert.usa-alert--slim {
  @include u-font('sans', 'xs');
  line-height: 2;
  padding: units(1);
  margin: 0;
  // .usa-alert__body {
  //   // padding-left: units(4);
  //   // padding-right: units(4);
  // }
}

.usa-alert:before {
  background: transparent;
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-mask: none;
  mask: none;
}

@each $icon, $i in $icons {
  .usa-alert--reduced {
    &:before {
      height: units(4);
      left: units(1);
      width: units(3);
    }
    @if $icon == 'info-circle' {
      &.usa-alert--info:before {
        background-image: url("#{$uri-svg-header-icons} stroke='#{url-friendly-color(color('info-dark'))}' #{$uri-svg-icons-style-outline} stroke-width='2'%3E#{$i}%3C/svg%3E");
      }
    }
    @if $icon == 'triangle-exclaim' {
      &.usa-alert--warning:before {
        background-image: url("#{$uri-svg-header-icons} stroke='#{url-friendly-color(color('warning-dark'))}' #{$uri-svg-icons-style-outline} stroke-width='2'%3E#{$i}%3C/svg%3E");
      }
    }
    @if $icon == 'check' {
      &.usa-alert--success:before {
        background-image: url("#{$uri-svg-header-icons} stroke='#{url-friendly-color(color('success-dark'))}' #{$uri-svg-icons-style-outline} stroke-width='2'%3E#{$i}%3C/svg%3E");
      }
    }
    @if $icon == 'close' {
      &.usa-alert--error:before {
        background-image: url("#{$uri-svg-header-icons} stroke='#{url-friendly-color(color('error-dark'))}' #{$uri-svg-icons-style-outline} stroke-width='2'%3E#{$i}%3C/svg%3E");
      }
    }
  }
  @if $icon == 'info-circle' {
    .usa-alert--info:before {
      background-image: url("#{$uri-svg-header-icons} stroke='#{url-friendly-color(color('info-dark'))}' #{$uri-svg-icons-style-outline} stroke-width='3'%3E#{$i}%3C/svg%3E");
    }
  }
  @if $icon == 'triangle-exclaim' {
    .usa-alert--warning:before {
      background-image: url("#{$uri-svg-header-icons} stroke='#{url-friendly-color(color('warning-dark'))}' #{$uri-svg-icons-style-outline} stroke-width='3'%3E#{$i}%3C/svg%3E");
    }
  }
  @if $icon == 'check' {
    .usa-alert--success:before {
      background-image: url("#{$uri-svg-header-icons} stroke='#{url-friendly-color(color('success-dark'))}' #{$uri-svg-icons-style-outline} stroke-width='3'%3E#{$i}%3C/svg%3E");
    }
  }
  @if $icon == 'close' {
    .usa-alert--error:before {
      background-image: url("#{$uri-svg-header-icons} stroke='#{url-friendly-color(color('error-dark'))}' #{$uri-svg-icons-style-outline} stroke-width='3'%3E#{$i}%3C/svg%3E");
    }
  }
}
