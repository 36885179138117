.announcements {
  span.no-display {
    position: absolute;
    top: -999px;
  }

  td:has([data-status^='ACTIVE']) + td {
    & .action-item:last-child {
      display: none;
    }
  }

  td:has([data-status^='INACTIVE']) + td {
    & .action-item {
      &.inactivate {
        display: none;
      }
    }
  }

  .action {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    > .action-item {
      &.active {
        grid-column: 3;
      }

      &.edit {
        grid-column: 1;
      }

      &.inactivate {
        grid-column: 2;
      }
    }
  }

  & .action-date {
    font-size: 1rem;
    font-weight: 400;
  }

  & .action-time {
    font-size: 0.65rem;
    font-weight: 500;
  }
}

.preview-banner {
  background-color: #07648d;
  position: fixed;
  width: 100%;
  left: 0px;
  top: 76px;
  padding-top: 9px;
  padding-left: 15px;
  color: white;
  text-align: center;
  min-height: 48px;
  a {
    font-weight: bold;
    text-decoration: underline;
    color: white;
  }
}

.announcement-banner {
  background-color: #07648d;
  width: 100%;
  padding-top: 13px;
  padding-left: 15px;
  color: white;
  text-align: center;
  min-height: 48px;
  border-bottom: 1px solid #4b89a5;
  padding-bottom: 9px;
  a {
    color: white;
    text-decoration: underline;
    font-weight: bold;
  }
}

.header {
  & .sort {
    &.dateCreated,
    &.dateUpdated,
    &.user,
    &.status {
      display: flex !important;
    }
  }
}

.usa-button.clickable.announcement-btn {
  background: beige;
  &:hover {
    background: white;
  }
}

.preview-button {
  display: inline-block;
  min-width: 40px;
  height: 26px;
  margin-top: 10px;
}
