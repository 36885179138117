@import '../settings/colors.scss';

.usa-button {
  margin-right: 0;
}

/*@media (min-width: 30em) {
  .usa-button {
    width: 100%;
  }
}*/

.width-max-content {
  width: max-content;
}

.box-shadow-0 {
  box-shadow: none !important;
}

.non-clickable,
.non-clickable * {
  pointer-events: none !important;
}

/**
 * ACCESSIBILITY: Ensures link touch area meets 508 Success Criterion 2.5.5
 * which specifies a minimum target size of 44px by 44px
 */
.minh-button {
  min-height: 44px;
}
.minw-button {
  min-width: 44px;
}

@each $color, $c in $colors {
  @each $subcolor, $sub in $c {
    @if $subcolor != 'palette' {
      $states: (
        'focus': $sub,
        'hover': color.adjust($sub, $blackness: 10%),
        'active': color.adjust($sub, $blackness: 15%),
      );
      @if $subcolor == 'text' {
        .usa-button--#{$color} {
          color: #{$sub};
          a {
            text-decoration: none;
          }
          @each $state, $s in $states {
            &:#{$state},
            &.usa-button--#{$state},
            &.#{$state} {
              color: #{$s};
              a {
                text-decoration: none;
              }
            }
          }
          &:disabled,
          &.disabled {
            color: color('white');
            background-color: rgba(color($color), 0.5);
            opacity: 0.5;
            pointer-events: none;
          }
        }
      }

      @if $subcolor == 'bg' {
        .usa-button--#{$color} {
          background-color: #{$sub};
          box-shadow: none;
          text-decoration: none;
          @each $state, $s in $states {
            &:#{$state},
            &.usa-button--#{$state},
            &.#{$state} {
              background-color: #{$s};
            }
          }

          &.usa-button--link {
            background-color: transparent;
            box-shadow: none;
            color: #{$sub};

            @each $state, $s in $states {
              &:#{$state},
              &.usa-button--#{$state},
              &.#{$state} {
                color: #{$s};
                box-shadow: none;
                @if index($onDark, $color) {
                  background-color: rgba(255, 255, 255, 0.15);
                } @else {
                  background-color: rgba(0, 0, 0, 0.15);
                }
              }
              &:disabled,
              &.disabled {
                background-color: transparent;
                box-shadow: none;
                color: color('base');
              }
            }

            &.usa-button--thin {
              box-shadow: none;
              font-style: normal;
              @each $state, $s in $states {
                &:#{$state},
                &.usa-button--#{$state},
                &.#{$state} {
                  color: #{$s};
                  box-shadow: none;
                  @if index($onDark, $color) {
                    background-color: rgba(255, 255, 255, 0.15);
                  } @else {
                    background-color: rgba(0, 0, 0, 0.15);
                  }
                }
                &:disabled,
                &.disabled {
                  box-shadow: none;
                }
              }
            }
          }

          &.usa-button--outline:not(.usa-button--outline-hover) {
            background-color: transparent;
            box-shadow: inset 0 0 0 2px #{$sub};
            color: #{$sub};

            @each $state, $s in $states {
              &:#{$state},
              &.usa-button--#{$state},
              &.#{$state} {
                color: #{$s};
                box-shadow: inset 0 0 0 2px #{$s};
                @if index($onDark, $color) {
                  background-color: rgba(255, 255, 255, 0.15);
                } @else {
                  background-color: rgba(0, 0, 0, 0.15);
                }
              }
              &:disabled,
              &.disabled {
                background-color: transparent;
                box-shadow: inset 0 0 0 2px color('base');
                color: color('base');
              }
            }

            &.usa-button--thin {
              box-shadow: inset 0 0 0 1px #{$sub};
              font-style: normal;
              @each $state, $s in $states {
                &:#{$state},
                &.usa-button--#{$state},
                &.#{$state} {
                  color: #{$s};
                  box-shadow: inset 0 0 0 1px #{$s};
                  @if index($onDark, $color) {
                    background-color: rgba(255, 255, 255, 0.15);
                  } @else {
                    background-color: rgba(0, 0, 0, 0.15);
                  }
                }
                &:disabled,
                &.disabled {
                  box-shadow: inset 0 0 0 1px color('base');
                }
              }
            }
          }

          &.usa-button--outline-hover {
            background-color: transparent;
            color: #{$sub};

            @each $state, $s in $states {
              &:#{$state},
              &.usa-button--#{$state},
              &.#{$state} {
                color: #{$s};
                box-shadow: inset 0 0 0 2px #{$s};
              }
              &:disabled,
              &.disabled {
                color: color('base');
                background-color: transparent;
              }
            }

            &.usa-button--thin {
              @each $state, $s in $states {
                &:#{$state},
                &.usa-button--#{$state},
                &.#{$state} {
                  color: #{$s};
                  box-shadow: inset 0 0 0 1px #{$s};
                }
              }
            }
          }
        }
      }
    }
  }
}

.search-button {
  .usa-button--primary {
    background-color: #276dc1;
    color: #ffffff;
    &:disabled,
    &.disabled {
      background-color: #276dc1;
      color: #ffffff;
      opacity: 1;
    }
  }
}
.usa-button {
  .button-text-with-2x-icon {
    width: calc(100% - 1rem);
  }
}
