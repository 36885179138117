.homepage_boxes {
  width: 100%;
  height: fit-content;
  gap: 1rem;
  display: grid;
  grid-template-rows: repeat(3, 1fr);

  & .data-grid {
    margin: 0;
    & .table.table-striped {
      width: 100%;
    }
    & table tr td {
      text-align: center;
    }
  }

  & > [id^='HBX'] {
    box-shadow: 2px 2px rgba(0, 37, 64, 0.35);
    border: 2px solid #07648d;
    display: grid;
    padding: 1rem;
    border-radius: 3px;
    justify-items: center;
    grid-template-rows: 50px 50px 1fr 100px;

    & > .hpb-text {
      margin: 0 0 1rem 0;
    }

    & > .hpb-actions {
      display: grid;
      align-items: end;
      width: 100%;
      text-align: center;
    }
  }
}

.homepage-boxes {
  width: 99%;
}
