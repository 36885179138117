@import '../settings/icons.scss';
@import '../settings/colors.scss';
@import '../settings/sizes.scss';

// EDIT delimeter icon names here:
//// NOTE icons names must already be defined in icons.scss
$delimeters: (
  'pipe',
  'doublerightarrow',
  'bullet',
  'plus',
  'minus',
  'forward-slash'
);

$alignments: ('text-bottom', 'middle', 'text-top');
$distances: (
  '0': 0,
  '1px': units(1px),
  '2px': units(2px),
  '1': units(1),
  '2': units(2),
);

// DO NOT EDIT below this line
// ---------------------------------

@function url-friendly-color($color) {
  @return '%23' + str-slice('#{$color}', 2, -1);
}

@each $color, $c in $colors {
  $text: map-get($c, 'text');
  .theme-#{$color} {
    @each $delimeter in $delimeters {
      @each $icon, $i in $icons {
        @if $icon == $delimeter {
          .separator--#{$delimeter}:after {
            @each $thickness, $t in $thicknesses {
              @if $thickness == 'thin' {
                $style: if(
                  $icon == 'bullet',
                  "#{$uri-svg-icons-style-fill} fill='#{url-friendly-color($text)}'",
                  "#{$uri-svg-icons-style-outline} stroke='#{url-friendly-color($text)}' stroke-width='#{$t}'"
                );
                background-image: url('#{$uri-svg-header-icons} #{$style} %3E#{$i}%3C/svg%3E');
              }
            }
          }
        }
      }
    }
  }

  .separator {
    &.separator-#{$color} {
      $bg: map-get($c, 'bg');
      @each $delimeter in $delimeters {
        @each $icon, $i in $icons {
          @if $icon == $delimeter {
            &.separator--#{$delimeter}:after {
              @each $thickness, $t in $thicknesses {
                @if $thickness == 'thin' {
                  $style: if(
                    $icon == 'bullet',
                    "#{$uri-svg-icons-style-fill} fill='#{url-friendly-color($bg)}'",
                    "#{$uri-svg-icons-style-outline} stroke='#{url-friendly-color($bg)}' stroke-width='#{$t}'"
                  );
                  background-image: url('#{$uri-svg-header-icons} #{$style} %3E#{$i}%3C/svg%3E');
                }
              }
            }
          }
        }
      }
    }
  }
}
.separator {
  & > * {
    position: relative;
  }

  &:after {
    background-position: center center;
    background-repeat: no-repeat;
    content: '';
    display: inline-flex;
    vertical-align: text-bottom;
    height: 100%;
    @include u-height(2);
    @include u-width(2);
    opacity: 0.5;
  }

  @each $size, $s in $sizes-smaller {
    &.separator-size--#{$size}:after {
      @include u-height(#{$s});
      @include u-width(#{$s});
    }
  }

  @each $alignment in $alignments {
    &.separator-align--#{$alignment}:after {
      vertical-align: #{$alignment};
    }
  }

  @each $distance, $d in $distances {
    &.separator-distance--#{$distance}:after {
      margin: 0 #{$d};
    }
  }
}
