.admin-dashboard {
  margin-top: 20px;
}

.account-admin {
  .account-admin-form {
    display: flex;
    flex-wrap: wrap;
  }
  .field-name {
    margin-right: 10px;
    margin-left: 5px;
    min-width: 120px;
  }
  .field-input-wrapper {
    margin-right: 10px;
    margin-left: 5px;
  }
  .account-field {
    display: flex;
    margin-top: 10px;
  }
  .submit {
    background-color: #00487c;
    color: white;
    border-radius: 5px;
    padding: 5px 20px 5px 20px;
    border: 0;
    font-size: 15px;
    margin-top: 20px;
  }
  .results {
    margin-top: 20px;
  }
}
