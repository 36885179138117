//SIZES
$sizes-smaller: (
  '3xs': 1px,
  '2xs': 2px,
  'xs': 05,
  'sm': 1,
  'md': 105,
  'lg': 2,
  'xl': 205,
  '2xl': 3,
  '3xl': 4,
);

$sizes: (
  // '3xs': 1,
  '2xs': 105,
  'xs': 2,
  'sm': 3,
  'md': 4,
  'lg': 6,
  'xl': 10,
  // '2xl': 'card',
  // '3xl': 'card-lg',
  // 'full': 'full',
);

$thicknesses: (
  // 'thinnest': '.25',
  // 'thinner': '.5',
  'thin': '1',
  'thick': '2',
  'thicker': '4',
  // 'thickest': '6',
);

$percents: (
  '100': 100%,
  '90': 90%,
  '80': 80%,
  '70': 70%,
  '60': 60%,
  '50': 50%,
  '40': 40%,
  '30': 30%,
  '20': 20%,
  '10': 10%,
);

//Additional Sizes for Display
.width-12 {
  width: 6rem;
}

.font-sans-4xs {
  @include u-font-family('sans');
  font-size: 0.8rem;
}
.font-sans-5xs {
  @include u-font-family('sans');
  font-size: 0.65rem;
}
