.data-grid {
  border: 1px solid lightgray;
  border-radius: 3px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05);
  background-color: white;
  padding: 0.5rem;
  table,
  tr,
  td {
    border-bottom: 1px solid #005ea2;
    border-collapse: collapse;
    padding: 0.5rem 0;

    & tr {
      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
      & th {
        margin: 0 0.25rem 0 0;
      }

      & td:not(:first-child) {
        text-align: center;
      }
      & td:last-child {
        text-align: left;
      }

      & .action {
        & > .action-item {
          &:hover {
            box-shadow: 0 2px 2px rgba(0, 37, 64, 0.35);
            cursor: pointer;
          }
        }
      }
    }
  }

  flex: 4 1 auto;
  margin: 3.6rem 0 0 0;
  overflow-x: scroll;
  & > .search-caption {
    font-size: 1.6rem;
    font-weight: 400;
    margin: 0 0 1.6rem 0;
    // color: $drk-gray-50;
    display: flex;
    justify-content: space-between;
  }

  & .header {
    display: flex;
    justify-content: center;
    gap: 0.25rem;
    text-transform: capitalize;
    white-space: nowrap;
    & .sort {
      // display: flex;
      display: none;

      & > div {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  tbody {
    & .actions {
      display: grid;
      flex-flow: column;
      gap: 1rem;

      button:not(:last-child) {
        margin: 0 1rem 0 0;
      }
    }
    & > tr {
      & > td {
        vertical-align: middle;
      }
    }
  }
}
