@media screen and (min-width: units(tablet)) {
  .tablet\:flex-row {
    flex-direction: row;
    &.tablet\:flex-gap {
      & > *:not(:last-child) {
        margin-right: units(2);
      }
    }
    &.tablet\:flex-gap-lg {
      & > *:not(:last-child) {
        margin-right: units(4);
      }
    }
  }
  .tablet\:flex-column {
    flex-direction: column;
  }
  .tablet\:maxw-card-lg {
    max-width: units(card-lg);
  }

  .tablet\:height-auto {
    height: auto;
  }
  .tablet\:not-ellipsed {
    text-overflow: unset;
    overflow: visible;
    white-space: pre-wrap;
  }
}

@media screen and (min-width: units(desktop-lg)) {
  .desktop-lg\:flex-row {
    flex-direction: row;
    &.desktop-lg\:flex-gap {
      & > *:not(:last-child) {
        margin-right: units(2);
      }
    }
    &.desktop-lg\:flex-gap-lg {
      & > *:not(:last-child) {
        margin-right: units(4);
      }
    }
  }
}

.error-page {
  p {
    @include u-font('sans', 'md');
    &:not(:last-child) {
      padding-bottom: units(4);
    }
  }
}
