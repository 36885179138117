/* Custom properties */
$tooltip-arrow-size: 0.25rem;
$tooltip-color: color('base-darkest');

/* Wrapping */
.tooltip-wrapper {
  //display: flex;
  position: relative;
  vertical-align: middle;
}

/* Absolute positioning */
.tooltip-tip {
  @include u-font('sans', '2xs');
  background: $tooltip-color;
  -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  border-radius: 4px;
  padding: 0.5rem;
  color: color('base-lighter');
  line-height: 1;
  z-index: 999999;
  // max-width: calc(100vw - units(4));
  // white-space: nowrap;
  word-break: break-word;

  /* CSS border triangles */
  &::before {
    content: ' ';
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: $tooltip-arrow-size;
    margin-left: calc(#{$tooltip-arrow-size} * -1);
  }

  &.topCenter,
  &.bottomCenter {
    transform: translateX(-50%);
    &::before {
      left: 50%;
    }
  }

  &.topLeft,
  &.topCenter,
  &.topRight,
  &.top {
    &::before {
      top: 100%;
      border-top-color: $tooltip-color;
    }
  }

  &.topLeft,
  &.bottomLeft {
    //left: 0;
    &::before {
      left: units(2);
    }
  }

  &.topRight,
  &.bottomRight {
    //right: 0;
    &::before {
      //left: calc(attr(data-parent-width, inherit) - units(1));
      right: units(2); //calc(100% - units(1));
    }
  }

  &.right,
  &.rightCenter {
    &::before {
      left: calc(#{$tooltip-arrow-size} * -1);
      top: 50%;
      transform: translateX(0) translateY(-50%);
      border-right-color: $tooltip-color;
    }
  }

  &.bottomLeft,
  &.bottomCenter,
  &.bottomRight,
  &.bottom {
    &::before {
      bottom: 100%;
      border-bottom-color: $tooltip-color;
    }
  }

  &.left {
    left: auto;
    &::before {
      left: auto;
      right: calc(#{$tooltip-arrow-size} * -2);
      top: 50%;
      transform: translateX(0) translateY(-50%);
      border-left-color: $tooltip-color;
    }
  }
  a {
    color: color(primary-lighter);
    &:hover,
    &:focus {
      color: color(primary-light);
    }
  }
}
