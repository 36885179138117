@import '../../components/settings/colors.scss';
@import '../../components/settings/icons.scss';
@import '../../components/settings/sizes.scss';

.non-clickable,
.non-clickable * {
  pointer-events: none !important;
}

@each $color, $c in $colors {
  @each $subcolor, $sub in $c {
    @if $subcolor != 'palette' {
      $states: (
        'focus': $sub,
        'hover': darken($sub, 10%),
        'active': $sub,
      );

      @if $subcolor == 'bg' {
        a.usa-link--#{$color} {
          color: #{$sub};
          background-color: transparent;
          padding: 0;

          &.usa-link--external {
            @each $icon, $i in $icons {
              @if $icon == 'newtab' {
                &::before {
                  content: '';
                  background-image: url("#{$uri-svg-header-icons} stroke='#{url-friendly-color($sub)}' #{$uri-svg-icons-style-outline}%3E#{$i}%3C/svg%3E");
                  background-position: center center;
                  background-repeat: no-repeat;
                  background-size: 80%;
                  display: inline-block;
                  flex: none;
                  height: units(2);
                  margin-top: 2px;
                  width: units(2);
                }
                &::after {
                  content: none;
                  display: none;
                  margin-left: 0;
                  padding-left: 0;
                }
              }
            }
          }

          @each $state, $s in $states {
            &:#{$state},
            &.usa-button--#{$state},
            &.#{$state} {
              color: #{$s};
              background-color: transparent;
              text-decoration: underline;
            }
            &:disabled,
            &.disabled {
              background-color: transparent;
              color: color('base');
            }
            &.usa-link--external {
              @each $icon, $i in $icons {
                @if $icon == 'newtab' {
                  &::before {
                    background-image: url("#{$uri-svg-header-icons} stroke='#{url-friendly-color($s)}' #{$uri-svg-icons-style-outline}%3E#{$i}%3C/svg%3E");
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.usa-header,
.usa-footer {
  a,
  a:visited {
    color: color(base-lighter);
    text-decoration: none;
    &:hover {
      color: color(base-lightest);
    }
    &.usa-nav__link {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      text-align: right;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
