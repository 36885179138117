@import '../settings/colors.scss';

.usa-pill {
  @include u-radius('md');
  background-color: transparent;
  box-shadow: inset 0 0 0 1px color('primary');
  font-weight: normal;
  margin-bottom: units(1);
  margin-right: units(1);
  padding: units(1);
  text-decoration: none;
  @each $color, $c in $colors {
    @each $subcolor, $sub in $c {
      @if $subcolor == 'bg' {
        &.usa-pill--#{$color} {
          box-shadow: inset 0 0 0 1px #{$sub};
          color: #{$sub};
        }
      }
    }
  }

  //  case 'disabled':
  //       borderColor = 'base-dark'
  //       defaultLabel = 'Disabled'
  //       fillColor = 'base-lightest'
  //       hoverColor = 'base-opacity-50'
  //       iconName = 'stop'
  //       break
  //     case 'flagged':
  //       borderColor = 'error-dark'
  //       defaultLabel = 'Flagged'
  //       fillColor = 'error-lighter'
  //       hoverColor = 'error-opacity-50'
  //       iconName = 'flag'
  //       break
  //     case 'unmoderated':
  //       borderColor = 'warning-dark'
  //       defaultLabel = 'Not Reviewed'
  //       fillColor = 'warning-lighter'
  //       hoverColor = 'warning-opacity-50'
  //       iconName = 'thumb-up-off'
  //       break
  //     case 'reviewed':
  //       // borderColor = 'success-dark'
  //       // fillColor = 'success-lighter'
  //       borderColor = 'accent-cool-darker'
  //       defaultLabel = 'Reviewed'
  //       fillColor = 'accent-cool-lighter'
  //       hoverColor = 'accent-cool-opacity-50'
  //       iconName = 'thumb-up'
  //       break
  //     case 'active':
  //       borderColor = 'accent-cool-darker'
  //       defaultLabel = 'Active'
  //       fillColor = 'accent-cool-lighter'
  //       hoverColor = 'accent-cool-opacity-50'
  //       // iconName = 'check'
  //       break
  //     case 'inactive':
  //       borderColor = 'base-dark'
  //       defaultLabel = 'Inactive'
  //       fillColor = 'base-lightest'
  //       hoverColor = 'base-opacity-50'
  //       // iconName = 'close'
  //       break
  //     case 'fullyDigitized':
  //       borderColor = 'success-darker'
  //       defaultLabel = 'Fully Digitized'
  //       fillColor = 'success-lighter'
  //       hoverColor = 'success-opacity-30'
  //       iconName = 'check'
  //       break
  //     case 'notDigitized':
  //       borderColor = 'warning-dark'
  //       defaultLabel = 'Not Digitized'
  //       fillColor = 'warning-lighter'
  //       hoverColor = 'warning-opacity-50'
  //       iconName = 'triangle-exclaim'
  //       break
  //     case 'unrestrictedOnly':
  //       borderColor = 'warning-dark'
  //       defaultLabel = 'Unrestricted Only'
  //       fillColor = 'warning-lighter'
  //       hoverColor = 'warning-opacity-50'
  //       iconName = 'triangle-exclaim'
  //       break
  //   }

  @each $color, $c in $colors {
    @if index($onDark, $color) {
      .theme-#{$color} {
        .usa-pill--success {
          border-color: color('success-dark');
          background-color: color('success-lighter');

          &.clickable:hover {
            background-color: color.adjust(
              color('success-lighter'),
              $blackness: 10%
            );
          }
        }
        .usa-pill--warning {
          border-color: color('warning-dark');
          background-color: color('warning-lighter');
          &.clickable:hover {
            background-color: color.adjust(
              color('warning-lighter'),
              $blackness: 10%
            );
          }
        }
        .usa-pill--secondary {
          border-color: color('secondary-dark');
          background-color: color('secondary-lighter');
          &.clickable:hover {
            background-color: color.adjust(
              color('secondary-lighter'),
              $blackness: 10%
            );
          }
        }
      }
    } @else {
      .theme-#{$color} {
        .usa-pill--success {
          border-color: color('success-lighter');
          background-color: color('success-darker');
          &.clickable:hover {
            background-color: color.adjust(
              color('success-lighter'),
              $blackness: 10%
            );
          }
        }
        .usa-pill--warning {
          border-color: color('warning-lighter');
          background-color: color('warning-darker');
          &.clickable:hover {
            background-color: color.adjust(
              color('warning-lighter'),
              $blackness: 10%
            );
          }
        }
        .usa-pill--secondary {
          border-color: color('secondary-lighter');
          background-color: color('secondary-dark');
          &.clickable:hover {
            background-color: color.adjust(
              color('secondary-lighter'),
              $blackness: 10%
            );
          }
        }
      }
    }
  }
}
